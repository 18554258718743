import http from '@/plugins/http'

export function getRoomList () {
  return new Promise((resolve, reject) => {
    http.get('/api/room').then(response => {
      console.log(response)
      const data = response.data
      if (data.code === 200) {
        resolve(data.data)
      } else {
        resolve([])
      }
    }).catch(e => {
      reject(e)
    })
  })
}

export function getSig (userId) {
  return http.get(`/api/room/${userId}/sig`)
}

export function getRoomInfo (roomId) {
  return new Promise((resolve, reject) => {
    http.get(`/api/room/${roomId}`).then(response => {
      console.log(response)
      const data = response.data
      if (data.code === 200) {
        resolve(data.data)
      } else {
        reject(data.message)
      }
    }).catch(e => {
      reject(e)
    })
  })
}
