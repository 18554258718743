import { getRoomInfo } from '@/api/room'
// module room
export default {
  namespaced: true,
  state: () => ({
    info: null,
    roomNotExist: false,
    microphoneOpen: false
  }),
  mutations: {
    setCurrentRoom (state, info) {
      console.log(info)
      state.info = info
    },
    toggleMicrophone (state, payload) {
      console.log('store 更新麦克风状态：' + payload.isOpen)
      state.microphoneOpen = payload.isOpen
    },
    toggleRoomNotExist (state, payload) {
      state.roomNotExist = payload.roomNotExist
    }
  },
  actions: {
    loadRoomInfo ({ commit }, payload) {
      // 查询房间信息
      // 设置临时信息
      commit('setCurrentRoom', {
        id: -1,
        name: '',
        coverPicture: '',
        createUserId: '',
        createUserName: '',
        createTime: 0
      })
      // 重置状态
      commit('toggleRoomNotExist', { roomNotExist: false })
      // 向接口查询
      getRoomInfo(payload.roomId).then(data => {
        commit('setCurrentRoom', data)
      }).catch(e => {
        console.log(e)
        alert('房间不存在!')
        commit('toggleRoomNotExist', { roomNotExist: true })
      })
    }
  }
}
