import Vue from 'vue'
import Vuex from 'vuex'
import moduleRoom from '@/store/modules/room'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      id: '',
      name: '',
      isLogin: false
    },
    currentRoute: 'home',
    showNavigation: true
  },
  mutations: {
    updateRoute (state, routeName) {
      state.currentRoute = routeName
    },
    login (state, user) {
      state.user = user
    },
    toggleNavigation (state, show) {
      state.showNavigation = show
    }
  },
  actions: {
    loginUser ({ commit }, user) {
      // 存储用户
      localStorage.setItem('user', JSON.stringify(user))
      commit('login', user)
      commit('toggleNavigation', true)
    },
    logout ({ commit }) {
      commit('login', {})
      localStorage.removeItem('user')
    }
  },
  modules: {
    moduleRoom
  }
})
