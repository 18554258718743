<template>
  <v-bottom-navigation
    v-model="routeValue"
    :value="routeValue"
    :input-value="showNavigation"
    color="primary"
    fixed
    grow
    app
  >
    <v-btn value="home">
      <span>Home</span>
      <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-btn value="favorites">
      <span>Favorites</span>
      <v-icon>mdi-heart</v-icon>
    </v-btn>

    <v-btn value="profile">
      <span>Profile</span>
      <v-icon>mdi-account</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'HomeNavigation',
  data: () => ({
    routeValue: 'home'
  }),
  watch: {
    routeValue: function (newRoute, oldRoute) {
      if (this.$route.name.toLowerCase() === newRoute) {
        return
      }
      this.$router.replace(`/${newRoute}`)
    },
    currentRoute (val) {
      console.log('current route watch : ' + val)
      this.routeValue = val.toLowerCase()
    }
  },
  computed: mapState([
    'currentRoute',
    'showNavigation'
  ])
}
</script>

<style scoped>
.v-item-group.v-bottom-navigation .v-btn.v-size--default {
  height: inherit;
}
</style>
