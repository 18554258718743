<template>
  <div class="dashboard-root">
    <v-main class="fill-height">
      <transition name="slide">
        <router-view></router-view>
      </transition>
    </v-main>
    <HomeNavigation />
  </div>
</template>

<script>
import HomeNavigation from '@/components/HomeNavigation'
export default {
  name: 'Dashboard',
  components: {
    HomeNavigation
  }
}
</script>

<style scoped>
  .dashboard-root {
    width: 100%;
    height: 100%;
  }
</style>
