<template>
  <v-app id="pano_live">
    <!-- <v-app-bar
      absolute
      color="#0B84C8"
      dark
      flat
      app
      dense
    >
      <v-spacer></v-spacer>
      <v-app-bar-title>直播列表</v-app-bar-title>
      <v-spacer></v-spacer>
    </v-app-bar> -->
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    //
  }),
  mounted () {
    // 检查登录
    this.loginCheck()
  },
  methods: {
    loginCheck () {
      const userStr = localStorage.getItem('user')
      if (userStr) {
        try {
          const user = JSON.parse(userStr)
          if (user.id && user.name) {
            user.isLogin = true
            this.$store.commit('login', user)
            return
          }
        } catch (e) {
          console.log(e)
        }
      }
      // 跳转登录
      this.$router.replace('/login')
    }
  }
}
</script>
