import Vue from 'vue'
import '@mdi/font/css/materialdesignicons.min.css'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify, {
  iconfont: 'mdi'
})

export default new Vuetify({
})
